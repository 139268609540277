<template>
  <div>
    <Navbar page="Gestão de Riscos" link="/entregaveis" nameLink="Entregáveis" link_2="/entregaveis/especifico" nameLink_2="Relatórios Específicos" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">

        <h1 class="text-3xl mb-1">Relatório Específico</h1>
        <a v-if="link" :href="link" target="_blank" class="text-base text-underline text-blue-500">Visualizar relatório: {{ link}}</a>

        <div class="grid grid-cols-12 gap-6 mt-7">
            <div class="col-span-12 md:col-span-5">
                <label for="tipo_relatorio" class="block text-sm font-medium"> 
                    Escolha o tipo do relatório
                </label>
                <select v-model="form.tipo_relatorio" name="tipo_relatorio" id="tipo_relatorio" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option value="status">Atividades para análise de risco com determinado status</option>
                    <option value="setor">Atividade de determinado setor</option>
                    <option value="responsavel">Atividades para análise de risco realizadas por determinado responsável</option>
                    <option value="grauDeRisco">Atividade para análises com determinado grau de risco</option>
                    <option value="probabilidade">Atividade para análises com determinada probalidade</option>
                    <option value="impacto">Atividade para análises com determinado impacto</option>
                    <option value="conduta">Atividade para análises com determinada conduta de mitigação</option>
                    <option value="impacto_titular">Atividade para análises com determinada impacto para titulares</option>
                    <option value="impacto_negocio">Atividade para análises com determinada impacto para negocios</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'status'" class="col-span-12 md:col-span-5">
                <label for="status" class="block text-sm font-medium">
                    Status da atividade
                </label>
                <select v-model="form.status" name="status" id="status" class="mt-2 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                  <option default value="Não Analisada">Não Analisada</option>
                  <option default value="Em Análise">Em Análise</option>
                  <option default value="Analisada pelo responsável">Analisada pelo responsável</option>
                  <option default value="Revisada pelo consultor">Revisada pelo consultor</option>
                  <option default value="Finalizado">Finalizado</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'setor'" class="col-span-12 md:col-span-5">
                <label for="setor" class="block text-sm font-medium">
                    Setor da atividade
                </label>
                <select required v-model="form.setor" name="setor" id="setor" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'grauDeRisco'" class="col-span-12 md:col-span-5">
                <label for="grauDeRisco" class="block text-sm font-medium">
                    Grau de risco da atividade
                </label>
                <select v-model="form.grauDeRisco" name="grauDeRisco" id="grauDeRisco" class="mt-2 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                  <option default value="muitobaixa">Muito baixa</option>
                  <option default value="baixo">Baixo</option>
                  <option default value="medio">Médio</option>
                  <option default value="alto">Alto</option>
                  <option default value="muitoalto">Muito Alto</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'probabilidade'" class="col-span-12 md:col-span-5">
                <label for="probabilidade" class="block text-sm font-medium">
                    Probabilidade
                </label>
                <select v-model="form.probabilidade" name="probabilidade" id="probabilidade" class="mt-2 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option value="muitobaixa">Muito Baixa</option>
                    <option value="baixo">Baixo</option>
                    <option value="medio">Médio</option>
                    <option value="alto">Alto</option>
                    <option value="muitoalto">Muito Alto</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'impacto'" class="col-span-12 md:col-span-5">
                <label for="impacto" class="block text-sm font-medium">
                    Impacto
                </label>
                <select v-model="form.impacto" name="impacto" id="impacto" class="mt-2 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option value="muitobaixa">Muito Baixo</option>
                    <option value="baixo">Baixo</option>
                    <option value="medio">Médio</option>
                    <option value="alto">Alto</option>
                    <option value="muitoalto">Muito Alto</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'responsavel'" class="col-span-12 md:col-span-5">
                <label for="responsavel" class="block text-sm font-medium">
                    Responsável pela atividade
                </label>
                <select required v-model="form.responsavel" name="responsavel" id="responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'conduta'" class="col-span-12 md:col-span-5">
                <label for="conduta" class="block text-sm font-medium">
                    Conduta da atividade
                </label>
                <select required v-model="form.conduta" name="conduta" id="conduta" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="conduta in condutas" :key="conduta._id" :value="conduta._id">{{ conduta.nome }}</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'impacto_titular'" class="col-span-12 md:col-span-5">
                <label for="impacto_titular" class="block text-sm font-medium">
                    Impacto para titulares
                </label>
                <select required v-model="form.impacto_titular" name="impacto_titulares" id="impacto_titulares" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="impacto in impactos_titular" :key="impacto._id" :value="impacto._id">{{ impacto.descricao }}</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'impacto_negocio'" class="col-span-12 md:col-span-5">
                <label for="impacto_negocio" class="block text-sm font-medium">
                    Impacto para negócios
                </label>
                <select required v-model="form.impacto_negocio" name="impacto_negocio" id="impacto_negocio" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="impacto in impactos_negocio" :key="impacto._id" :value="impacto._id">{{ impacto.descricao }}</option>
                </select>
            </div>
            
            <div class="col-span-12 md:col-span-2">
                <label class="block text-sm font-medium"> 
                    Buscar atividades
                </label>
                <button @click="buscar()" class="mt-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
            </div>
        </div>
        
        <div v-if="list && list.length" class="flex flex-col mt-4">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th v-if="!$store.state.user.cliente" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          </svg>
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Atividade para análise
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Setor da atividade
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Data
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="item in list" :key="item._id">
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm" id="titularmenor">
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              {{item.nome ? item.nome : ''}}
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              {{item.atividade && item.atividade.setor && item.atividade.setor.nome ? item.atividade.setor.nome : ''}}
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div v-if="item.dataAtualizacao" class="text-sm text-gray-500">
                              {{ item.dataAtualizacao | moment("DD/MM/YYYY HH:mm")}}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
              </div>
            </div>
        </div>

        <div v-if="selecionados && selecionados.length" class="grid grid-cols-12 gap-6 mt-4">
            <div class="col-span-12 md:col-span-4">
                <label for="titulo" class="block text-sm font-medium">
                    De um título para o relatório
                </label>
                <input v-model="form.titulo" type="text" name="titulo" id="titulo" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                <button @click="gerarRelatorio()" class="text-white bg-yellow-400 hover:bg-yellow-500 rounded py-1 px-5 mt-5">
                  Gerar Relatório
                </button>
            </div>
            <div class="col-span-12 md:col-span-6 bg-gray-100 py-4 px-4 rounded md:ml-6">
              <label class="block text-sm font-medium">
                  Atividades:
              </label>
              <div class="my-2" v-for="(item, idx) in selecionados" :key="idx">
                <span class="text-sm">{{ item.nome }}</span>
              </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            pessoas: [],
            condutas: [],
            setores: [],
            impactos_titular: [],
            impactos_negocio: [],
            list: [],
            total: 0,
            page: 1,
            perPage: 20,
            selecionados: [],
            busca: { limit: this.perPage },
            link: "",
            form: {
                tipo_relatorio: null,
                titulo: '',
                responsavel: null,
                setor: null,
                status: ''
            }
        }
    },
    methods: {
        async buscar(){
            this.busca = { limit: this.perPage };

            if(this.form.tipo_relatorio === 'status') {
                if(!this.form.status) return this.$vToastify.error("Escolha o status");
                this.busca.status = this.form.status;
            }

            if(this.form.tipo_relatorio === 'responsavel') {
                if(!this.form.responsavel) return this.$vToastify.error("Escolha o responsável");
                this.busca.responsavel = this.form.responsavel;
            }

            if(this.form.tipo_relatorio === 'setor') {
                if(!this.form.setor) return this.$vToastify.error("Escolha o setor");
                this.busca.setor = this.form.setor;
            }

            if(this.form.tipo_relatorio === 'conduta') {
                if(!this.form.conduta) return this.$vToastify.error("Escolha a conduta");
                this.busca.conduta = this.form.conduta;
            }

            if(this.form.tipo_relatorio === 'grauDeRisco') {
                if(!this.form.grauDeRisco) return this.$vToastify.error("Escolha o grau de risco");
                this.busca.grauDeRisco = this.form.grauDeRisco;
            }

            if(this.form.tipo_relatorio === 'probabilidade') {
                if(!this.form.probabilidade) return this.$vToastify.error("Escolha a probabilidade");
                this.busca.probabilidade = this.form.probabilidade;
            }

            if(this.form.tipo_relatorio === 'impacto') {
                if(!this.form.impacto) return this.$vToastify.error("Escolha o impacto");
                this.busca.impacto = this.form.impacto;
            }
            
            if(this.form.tipo_relatorio === 'impacto_titular') {
                if(!this.form.impacto_titular) return this.$vToastify.error("Escolha o impacto");
                this.busca.impacto_titular = this.form.impacto_titular;
            }

            if(this.form.tipo_relatorio === 'impacto_negocio') {
                if(!this.form.impacto_negocio) return this.$vToastify.error("Escolha o impacto");
                this.busca.impacto_negocio = this.form.impacto_negocio;
            }

            const listReq = await this.$http.post(`/v1/riscos/list`, this.busca);
            if(!listReq.data || !listReq.data.data.length) {
              this.list = [];
              this.total = 0;
              return this.$vToastify.error("Nenhuma atividade encontrada");
            }
            this.list = listReq.data.data;
            this.total = listReq.data.total;
            this.link = "";
        },

        async selecionarTodos () {
          for(let i = 0; i < this.list.length; i++){
            const item = this.list[i];
            if(this.selecionados.map(el => el._id).indexOf(item._id) ==! -1) this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
            else this.selecionados.push(item);
          }
        },

        async setPage(page) {
            this.page = page;
            const skip = (page - 1) * this.perPage;
            const limit = this.perPage;
            
            this.busca.skip = skip;
            this.busca.limit = limit;
            const listReq = await this.$http.post(`/v1/riscos/list`, this.busca);
            this.list = listReq.data.data;
            this.total = listReq.data.total;
        },

        selecionadosControl(item, e) {
          if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
            this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
          } else {
            this.selecionados.push(item);
          }
        },

        async gerarRelatorio(){
          const req = await this.$http.post(`/v1/entregaveis/especifico/riscos`, { 
            titulo: this.form.titulo, 
            tipo_relatorio: this.form.tipo_relatorio, 
            impacto_titular: this.form.impacto_titular,
            impacto_negocio: this.form.impacto_negocio,
            conduta: this.form.conduta,
            selecionados: this.selecionados.map(el => el._id) 
          });
          window.open(`${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis`);
          this.link = `${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis`;
        },
    },
    async beforeMount() {

        const listPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true});
        this.pessoas = listPessoas.data.data;

        const listSetores = await this.$http.post(`/v1/setores/list`, { all: true});
        this.setores = listSetores.data.data;

        const listCondutas = await this.$http.post(`/v1/condutasmitigacao/list`, { all: true});
        this.condutas = listCondutas.data.data;

        const listimpactosTitular = await this.$http.post(`/v1/impactos/list`, { all: true, impactado: "TITULARES DE DADOS"});
        this.impactos_titular = listimpactosTitular.data.data;

        const listimpactosNegocios = await this.$http.post(`/v1/impactos/list`, { all: true, impactado: "NEGOCIOS DA EMPRESA"});
        this.impactos_negocio = listimpactosNegocios.data.data;

    }
}
</script>